<template>
  <div class="chart-container">
    <div ref="chartRef" class="chart-wrap" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as echarts from 'echarts'
export default {
  name: 'PowerbankChart',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: '',
      option: {
        tooltip: {
          show: true,
          trigger: 'axis',
          triggerOn: 'click',
          padding: [5, 10, 5, 10],
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(153, 153, 153, 0.1)",
            },
          },
          formatter: params => {
            console.log('axisIndex', params[0]);
            const date = this.data?.[params[0].dataIndex].dateStr
            return `<div style="font-size:10px;color:#999">日期：${dayjs(date).format('YYYY-MM-DD')}</div><div style="font-size:10px;color:#333">${params[0].marker} 收益金额：${params[0].value}元</div><div style="font-size:10px;color:#333">${params[1].marker} 订单：${params[1].value}笔</div>`
          },
        },
        title: {
          show: false,
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#ECECEC',
            },
          },
          axisLabel: {
            fontSize: 10,
            color: '#878787',
          },
          splitLine: {
            show: false,
          },
          scale: true,
          min: 'dataMin',
          max: 'dataMax',
        },
        yAxis: [
          {
            name: '收益(元)',
            nameTextStyle: {
              fontSize: 11,
              color: '#878787',
            },
            axisLabel: {
              fontSize: 11,
              color: '#878787',
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
            min: 0,
            max: 'dataMax',
          },
          {
            name: '订单(笔)',
            nameTextStyle: {
              fontSize: 11,
              color: '#878787',
            },
            axisLabel: {
              fontSize: 11,
              color: '#878787',
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
            min: 0,
            max: 'dataMax',
          },
        ],
        series: [
          {
            name: '收益(元)',
            type: 'line',
            yAxisIndex: 0,
            showSymbol: false,
            symbolSize: 6,
            smooth: true,
            lineStyle: {
              color: '#FD6E05',
            },
            itemStyle: {
              color: '#FD6E05',
            },
          },
          {
            name: '订单(笔)',
            type: 'line',
            yAxisIndex: 1,
            showSymbol: false,
            symbolSize: 6,
            smooth: true,
            lineStyle: {
              color: '#FEC94C',
            },
            itemStyle: {
              color: '#FEC94C',
            },
          },
        ],
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const list = this.data || []
      const xAxisData = list.map(item => dayjs(item.dateStr).format('MM.DD'))
      const orderData = list.map(item => item.orderNum)
      const profitData = list.map(item => item.revenue)
      this.$nextTick(() => {
        this.chart = echarts.init(this.$refs.chartRef)
        this.option.xAxis.data = xAxisData
        this.option.series[0].data = profitData
        this.option.series[1].data = orderData
        this.chart.setOption(this.option)
        this.chart.resize({ height: 320 })
      })
    },
  },
}
</script>

<style scoped>
.chart-container {
  width: 100%;
  padding: 0 10px;
}
.chart-wrap {
  width: 100%;
  height: 270px;
}
</style>
