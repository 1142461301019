<template>
  <div class="index">
    <div class="index-box">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in swipers" :key="index">
          <van-image height="140px" :src="item.imageUrl" fit="cover" />
        </van-swipe-item>
      </van-swipe>
      <div class="charge">
        <div class="charge-content">
          <div class="flex-3">
            <p class="charge-title">今日充电宝收益(元)</p>
            <p class="text-title-lg">{{ powerBankStatistics?.todayRevenue || 0 }}</p>
          </div>
          <div class="flex-2">
            <p class="charge-title">今日充电笔数</p>
            <p class="text-title-lg">{{ powerBankStatistics?.todayOrderNum || 0 }}</p>
          </div>
        </div>
        <div class="charge-bottom">
          <div class="flex-3">
            <div class="charge-title">今日收款金额(元)</div>
            <div class="charge-nums">{{ deviceTransactionAmount || 0 }}</div>
          </div>
          <div class="flex-3">
            <div class="charge-title">今日收款笔数</div>
            <div class="charge-nums">{{ deviceTransactionCount || 0 }}</div>
          </div>
          <div class="flex-2">
            <div class="charge-title">交易记录</div>
            <router-link to="/transactionList">
              <div class="flex-x-start charge-details">
                <span>查看更多</span>
                <img src="~assets/images/common/arrow-right-white.png" alt />
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="powerbank">
        <div class="flex-x-between">
          <div class="text-section-title">充电宝信息</div>
          <router-link to="/order/powerbank">
            <div class="btn-more">
              <span class="text-more">查看更多</span>
              <img src="~assets/images/common/arrow-right.png" alt />
            </div>
          </router-link>
        </div>
        <div class="powerbank-card">
          <div class="powerbank-card_statistics">
            <div class="flex-1">
              <div class="text-label-primary">可提现金额(元)</div>
              <div class="text-value-primary">{{ merchantInfo?.pbWithdrawAbleAmount || 0 }}</div>
            </div>
            <router-link to="/withdrawal/index">
              <div class="btn-withdraw">申请提现</div>
            </router-link>
          
          </div>
          <div class="powerbank-income">
            <!-- <div class="powerbank-income_item">
              <div class="text-label">今日收益(元)</div>
              <div class="text-value">
                {{ powerBankStatistics?.todayRevenue || 0 }}
              </div>
            </div>
            <div class="powerbank-income_item">
              <div class="text-label">今日订单(笔)</div>
              <div class="text-value">
                {{ powerBankStatistics?.todayOrderNum || 0 }}
              </div>
            </div> -->
            <div class="powerbank-income_item">
              <div class="text-label">总收益(元)</div>
              <div class="text-value">
                {{ powerBankStatistics?.totalRevenue || 0 }}
              </div>
            </div>
            <div class="powerbank-income_item">
              <div class="text-label">总订单(笔)</div>
              <div class="text-value">
                {{ powerBankStatistics?.totalOrderNum || 0 }}
              </div>
            </div>
          </div>
          <div class="time-wrap">
            <div class="time-type">
              <div
                v-for="item in timeTypeList"
                :key="item.value"
                class="time-item"
                :class="{ 'time-item_selected': powerBankTimeType == item.value }"
                @click="onTimeTypeChange(item, 'powerBank')"
              >
                {{ item.label }}
              </div>
            </div>
            <div class="time">
              <div class="flex-1" @click="showPowerBankStartTimePicker = true">{{ dateFormat(powerBankStartTime, 'YYYY-MM-DD') }}</div>
              <div class="text-divider">至</div>
              <div class="flex-1" @click="showPowerBankEndTimePicker = true">{{ dateFormat(powerBankEndTime, 'YYYY-MM-DD') }}</div>
            </div>
          </div>
          <div class="chart-statistics">
            <div class="flex-3">
              <span>总收款金额：</span>
              <span class="color-primary">{{ powerBankChartData?.totalRevenue || 0 }}元</span>
            </div>
            <div class="flex-2">
              <span>总交易笔数：</span>
              <span class="color-primary">{{ powerBankChartData?.totalOrderNum || 0 }}笔</span>
            </div>
          </div>
          <PowerbankChart v-if="powerBankChartData?.statisticsList?.length > 0" :data="powerBankChartData?.statisticsList" />
          <div v-else class="empty-wrap">
            <img src="~assets/images/common/img_empty.png" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
      <template v-if="userType != 2">
        <!-- 设备数据 -->
        <div class="equipment">
          <div class="flex-x-between" style="margin-top: 20px;">
            <div class="text-section-title">设备信息</div>
            <router-link to="/transactionList">
              <div class="btn-more">
                <span class="text-more">查看更多</span>
                <img src="~assets/images/common/arrow-right.png" alt />
              </div>
            </router-link>
          </div>
          <div class="powerbank-card">
            <div class="time-wrap">
              <div class="time-type">
                <div
                  v-for="item in timeTypeList"
                  :key="item.value"
                  class="time-item"
                  :class="{ 'time-item_selected': deviceTimeType == item.value }"
                  @click="onTimeTypeChange(item, 'device')"
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="time">
                <div class="flex-1" @click="showDeviceStartTimePicker = true">{{ dateFormat(deviceStartTime, 'YYYY-MM-DD') }}</div>
                <div class="text-divider">至</div>
                <div class="flex-1" @click="showDeviceEndTimePicker = true">{{ dateFormat(deviceEndTime, 'YYYY-MM-DD') }}</div>
              </div>
            </div>
            <div class="chart-statistics">
              <div class="flex-3">
                <span>总收款金额：</span>
                <span class="color-primary">{{ deviceChartData?.totalTransactionAmount || 0 }}元</span>
              </div>
              <div class="flex-2">
                <span>总交易笔数：</span>
                <span class="color-primary">{{ deviceChartData?.totalTransactionNum || 0 }}笔</span>
              </div>
            </div>
            <DeviceChart v-if="deviceChartData?.statisticsList?.length > 0" :data="deviceChartData?.statisticsList" />
            <div v-else class="empty-wrap">
              <img src="~assets/images/common/img_empty.png" />
              <div>暂无数据</div>
            </div>
          </div>
          <div v-if="deviceList.length > 0" style="margin-top: 10px">
            <div v-for="item in deviceList" :key="item.id" class="item">
              <div class="flex-x-start flex-xy-center item-top">
                <img src="~/assets/images/common/sn-icon.png" alt />
                <span>刷脸设备SN：{{ item.deviceNumber }}</span>
              </div>
              <div class="device-item_text"><span class="label">充电宝柜SN：</span>{{ item.boxId || '-' }}</div>
              <div class="device-item_text"><span class="label">收款码SN：</span>{{ item.qrCodeSn || '-' }}</div>
            </div>
          </div>
          <van-empty v-else description="暂无数据" />
        </div>
      </template>
    </div>
    <foot-tabbar />
    <!-- 开始时间选择 -->
    <van-popup v-model="showPowerBankStartTimePicker" position="bottom">
      <van-datetime-picker v-model="powerBankStartTime" type="date" title="请选择开始时间"  @cancel="timeCancel('PowerBankStart')" @confirm="timeConfirm('PowerBankStart','PowerBank')"/>
    </van-popup>
    <!-- 结束时间选择 -->
    <van-popup v-model="showPowerBankEndTimePicker" position="bottom">
      <van-datetime-picker v-model="powerBankEndTime" type="date" title="请选择结束时间"  @cancel="timeCancel('PowerBankEnd')" @confirm="timeConfirm('PowerBankEnd','PowerBank')"  />
    </van-popup>
    <!-- 开始时间选择 -->
    <van-popup v-model="showDeviceStartTimePicker" position="bottom">
      <van-datetime-picker v-model="deviceStartTime" type="date" title="请选择开始时间" @cancel="timeCancel('DeviceStart')"   @confirm="timeConfirm('DeviceStart','Device')"/>
    </van-popup>
    <!-- 结束时间选择 -->
    <van-popup v-model="showDeviceEndTimePicker" position="bottom">
      <van-datetime-picker v-model="deviceEndTime" type="date" title="请选择结束时间" @cancel="timeCancel('DeviceEnd')" @confirm="timeConfirm('DeviceEnd','Device')"/>
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import FootTabbar from '@/components/FootTabbar'
import PowerbankChart from './PowerbankChart.vue'
import DeviceChart from './DeviceChart.vue'
import { setCookie } from 'utils/cookie'
import {
  transactionRecordIndex,
  getSwiper,
  getDeviceRewardConfig,
  login,
  wechatAuthInfo,
  merchantInfo,
  userInfo,
  getIndexDataSwitch,
} from 'api/common'
import {
  getPowerBankStatistics,
  getPowerBankChartData,
  getDeviceList,
  getDeviceTransactionAmount,
  getDeviceTransactionCount,
  getDeviceChartData,
} from 'api/home'

export default {
  name: 'Index',
  components: {
    FootTabbar,
    PowerbankChart,
    DeviceChart,
  },
  data() {
    return {
      swipers: [],
      indexData: {},
      ableEquip: {},
      abledEquip: {},
      page: 1,
      pageSize: 100,
      deviceList: [],
      showEfficientTransaction: false, // 有效交易开关
      showHandlingFee: false, // 手续费开关
      showDeviceReward: false,
      userType: localStorage.getItem('userType'),
      timeTypeList: [
        { label: '近七天', value: 1 },
        { label: '本月', value: 2 },
        { label: '上月', value: 3 },
      ],
      merchantInfo: '',
      powerBankTimeType: 1,
      powerBankStartTime: '',
      powerBankEndTime: '',
      powerBankStatistics: '',
      powerBankChartData: '',
      deviceTimeType: 1,
      deviceStartTime: '',
      deviceEndTime: '',
      deviceTransactionAmount: '',
      deviceTransactionCount: '',
      deviceChartData: '',
      showPowerBankStartTimePicker: false,
      showPowerBankEndTimePicker: false,
      showDeviceStartTimePicker: false,
      showDeviceEndTimePicker: false,
    }
  },
  created() {
    const { account, password, token } = this.$route.query
    // 这里是设备端打开，必须登录
    if (account && password) {
      localStorage.removeItem('payToken')
      localStorage.removeItem('payInfo')
      localStorage.removeItem('bankName')
      localStorage.removeItem('businessScopeName')
      this.login(account, password)
    } else {
      const savedToken = token || localStorage.getItem('payToken')
      localStorage.setItem('payToken', savedToken)
      if (!savedToken) {
        this.$router.push('/login')
        return
      }
      this.initTime()
      this.init()
    }
  },
  mounted() {
    // Android端调用的方法
    window.refresh = () => this.init()
  },
  methods: {
    timeConfirm(type,loadType){
      this[`show${type}TimePicker`] = false
      loadType == 'PowerBank'?this.loadPowerBankChartData():this.loadDeviceChartData()
    },
    timeCancel(type){
      this[`show${type}TimePicker`] = false
    },
    dateFormat(val, format) {
      return dayjs(val).format(format)
    },
    initTime() {
      this.powerBankStartTime = dayjs()
        .subtract(6, 'day')
        .toDate()
      this.deviceStartTime = dayjs()
        .subtract(6, 'day')
        .toDate()
      this.powerBankEndTime = dayjs().toDate()
      this.deviceEndTime = dayjs().toDate()
      this.loadPowerBankChartData()
      this.loadDeviceChartData()
    },
    init() {
      this.getData()
      this.getSwipers()
      this.getDeviceRewardConfig()
      this.getMerchantInfo()
      this.getDataSwitch()
      this.loadDeviceStatistics()
      this.loadPowerBankStatistics()
      this.loadDeviceList()
    },
    login(account, password) {
      login({ account, password }).then(res => {
        if (res.code === 200) {
          setCookie(account, password, 7)

          localStorage.setItem('payToken', res.data.token)
          localStorage.setItem('payInfo', JSON.stringify(res.data.merchant))
          localStorage.setItem('bankName', JSON.stringify(res.data.bankName))
          localStorage.setItem('businessScopeName', JSON.stringify(res.data.businessScopeName))
          this.getWechatAuthInfo()
          this.init()
        }
      })
    },
    getMerchantInfo() {
      const func = this.userType == 2 ? userInfo : merchantInfo
      func().then(res => {
        if (res.code === 200) {
          if (this.userType == 2) {
            const { merchant, merchantStore } = res.data
            this.merchantInfo = merchant
            console.log('======', merchant, merchantStore)
            localStorage.setItem('payInfo', JSON.stringify(merchant))
            localStorage.setItem('merchantStore', JSON.stringify(merchantStore))
          } else {
            this.merchantInfo = res.data
            localStorage.setItem('payInfo', JSON.stringify(res.data))
          }
        }
      })
    },
    getWechatAuthInfo() {
      wechatAuthInfo().then(res => {
        if (res.code === 200) {
          localStorage.setItem('wechatAuthInfo', JSON.stringify(res.data))
        }
      })
    },
    // 获取轮播图
    getSwipers() {
      getSwiper().then(res => {
        if (res.code === 200) {
          this.swipers = res.data
          console.log(this.swipers)
        }
        console.log(res)
      })
    },
    // 获取首页数据
    getData() {
      transactionRecordIndex().then(res => {
        if (res.code === 200) {
          this.indexData = res.data
          this.ableEquip = res.data.withdrawableDeviceCount
          this.abledEquip = res.data.withdrawabledDeviceCount
          this.$store.commit('set_commissionByMonth', res.data.commissionByMonth)
          this.$store.commit('set_allCommission', res.data.allCommission)
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    getDeviceRewardConfig() {
      getDeviceRewardConfig().then(res => {
        if (res.code == 200) {
          this.showDeviceReward = res.data.openSwitch
        }
      })
    },
    getDataSwitch() {
      getIndexDataSwitch().then(res => {
        if (res.code == 200) {
          const { efficientTransactionSwitch, handlingFeeSwitch } = res.data
          this.showEfficientTransaction = efficientTransactionSwitch
          this.showHandlingFee = handlingFeeSwitch
        }
      })
    },
    loadDeviceList() {
      getDeviceList().then(res => {
        this.deviceList = res.data?.list || []
      })
    },
    loadDeviceStatistics() {
      getDeviceTransactionAmount().then(res => {
        this.deviceTransactionAmount = res.data
      })
      getDeviceTransactionCount().then(res => {
        this.deviceTransactionCount = res.data
      })
    },
    loadPowerBankStatistics() {
      getPowerBankStatistics().then(res => {
        if (res.code != 200) return
        this.powerBankStatistics = res.data
      })
    },
    loadPowerBankChartData() {
      const params = {
        queryTimeUnit: 2,
        startStatisticsTime: dayjs(this.powerBankStartTime).format('YYYY-MM-DD 00:00:00'),
        endStatisticsTime: dayjs(this.powerBankEndTime).format('YYYY-MM-DD 23:59:59'),
      }
      getPowerBankChartData(params).then(res => {
        if (res.code != 200) return
        this.powerBankChartData = res.data
      })
    },
    loadDeviceChartData() {
      const params = {
        queryTimeUnit: 2,
        startStatisticsTime: dayjs(this.deviceStartTime).format('YYYY-MM-DD 00:00:00'),
        endStatisticsTime: dayjs(this.deviceEndTime).format('YYYY-MM-DD 23:59:59'),
      }
      getDeviceChartData(params).then(res => {
        if (res.code != 200) return
        this.deviceChartData = res.data
      })
    },
    onTimeTypeChange(item, type) {
      if (this[`${type}TimeType`] == item.value) return
      this[`${type}TimeType`] = item.value
      if (item.value == 1) {
        this[`${type}StartTime`] = dayjs()
          .subtract(6, 'day')
          .format('YYYY-MM-DD')
        this[`${type}EndTime`] = dayjs().format('YYYY-MM-DD')
      } else if (item.value == 2) {
        this[`${type}StartTime`] = dayjs()
          .startOf('month')
          .format('YYYY-MM-DD')
        this[`${type}EndTime`] = dayjs().format('YYYY-MM-DD')
      } else {
        this[`${type}StartTime`] = dayjs()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD')
        this[`${type}EndTime`] = dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      const func = type == 'powerBank' ? this.loadPowerBankChartData : this.loadDeviceChartData
      func()
    },
    onTimeClick(type) {
      this.$refs[`${type}Picker`].open()
    },
  },
}
</script>

<style lang="less" scoped>
.index {
  padding-bottom: 60px;

  .index-box {
    padding: 15px;
    background: #f9f9f9;

    /deep/.van-image {
      width: 100%;
    }

    .item {
      background: #fff;
      padding: 15px;
      margin-bottom: 15px;

      .item-top {
        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }

      .item-foot {
        margin-top: 14px;

        .foot-left {
          div {
            font-size: 14px;
            margin-left: 10px;
            padding: 4px;
            color: grey;
          }

          .line {
            margin-top: 0.1rem;
            width: 0.026667rem;
            height: 0.4rem;
            opacity: 0.08;
            border-right: 0.026667rem solid #333333;
          }

          p {
            color: rgba(51, 51, 51, 0.8);
          }
        }

        .item-right {
          font-size: 16px;
          color: #1e2431;
        }
      }
    }

    .charge {
      margin-top: 15px;
      background-image: url('~assets/images/index/yj.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-top: 30px;
      overflow: hidden;
      color: #ffffff;

      .charge-content {
        display: flex;
        padding: 0 20px;
      }

      .charge-bottom {
        display: flex;
        width: 100%;
        margin-top: 30px;
        padding: 12px 20px;
        border-radius: 0px 0px 4px 4px;
        background: linear-gradient(90.9deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.05) 100%);
      }

      .charge-title {
        font-size: 12px;
      }

      .charge-num {
        margin-top: 6px;
        font-size: 25px;
      }

      .charge-nums {
        margin-top: 6px;
        font-size: 18px;
      }

      .charge-center {
        padding-top: 20px;
      }

      .charge-bottom {
        padding-top: 10px;
      }

      .charge-details {
        span {
          margin-top: 10px;
          color: #fff;
        }

        img {
          margin-top: 12px;
          height: 10px;
          width: 10px;
          margin-left: 5px;
        }
      }
    }

    .trade {
      margin: 15px 0 0 0;
      padding: 12px 20px;
      background: #fff;
      border-radius: 4px;

      .line {
        width: 1px;
        height: 18px;
        opacity: 0.08;
        border-right: 1px solid #333333;
      }

      .tradebox {
        p {
          font-size: 12px;
          color: rgba(51, 51, 51, 0.85);
          margin-bottom: 6px;
        }

        div {
          font-size: 16px;
          color: #333333;
        }

        button {
          background: transparent;
        }

        a {
          font-size: 11px;
          color: rgba(51, 51, 51, 0.34);

          img {
            height: 13px;
            width: 13px;
          }
        }
      }
    }

    .fee {
      margin: 15px 0 0 0;

      .today {
        width: 168px;
        padding: 12px 20px;
        background: #fff;
        background-image: url('~assets/images/index/today.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .month {
        width: 168px;
        background: #fff;
        padding: 12px 20px;
        background-image: url('~assets/images/index/month.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .fee-title {
        font-size: 12px;
        margin-bottom: 7px;
      }

      .fee-num {
        font-size: 16px;
      }
    }

    .equipment {
      .equipment-title {
        margin: 20px 0 12px 0;
        font-size: 15px;
        font-weight: 600;
      }

      .equipment-item {
        width: 168px;
        height: 124px;
        box-sizing: border-box;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 20px;

        p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
        }

        div {
          font-size: 20px;
          color: #f9f9f9;
          margin: 5px 0 20px 0;
        }

        a {
          // display: inline-block;
          padding: 4px 10px 2px 10px;
          background: #ffffff;
          color: #ccac8b;
          font-size: 13px;
          border-radius: 11px;
          box-shadow: 0px 0px 6px 0px rgba(184, 149, 113, 0.6);
        }
      }

      .equipment-left {
        background-image: url('~assets/images/index/ktxsb.png');
      }

      .equipment-right {
        background-image: url('~assets/images/index/jlsb.png');

        a {
          color: #e86200;
        }
      }
    }
  }
}

.powerbank-card {
  margin-top: 12px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(51, 51, 51, 0.03);
  overflow: hidden;
}

.powerbank-card_statistics {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(65.5deg, rgba(252, 155, 83, 1) 0%, rgba(253, 110, 5, 1) 100%);
}

.time-wrap {
  display: flex;
  align-items: center;
  padding: 12px 15px;

  .time-type {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .time-item {
    margin-right: 12px;
    padding: 3px 6px;
    color: #a8a8a8;
    font-weight: 400;
    font-size: 12px;

    &_selected {
      color: #fd6e05;
      background-color: rgba(#fd6e05, 0.1);
    }
  }

  .time {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 3px 6px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #eee;
    font-size: 11px;
    color: #999;

    .text-divider {
      flex-shrink: 0;
      padding: 0 5px;
      color: #333;
    }
  }
}

.chart-statistics {
  display: flex;
  align-items: center;
  margin: 0 15px 0 15px;
  padding: 11px 14px;
  border-radius: 5px;
  background-color: #f8f8f8;
  font-size: 12px;
  color: #333;
}

.powerbank {
  margin-top: 20px;

  .btn-withdraw {
    width: 72px;
    height: 28px;
    line-height: 28px;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(222, 94, 0, 0.6);
    text-align: center;
    color: #e86200;
    font-size: 13px;
  }

  .powerbank-income {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 19px;
    grid-column-gap: 23px;
    column-gap: 23px;
    row-gap: 19px;
    padding: 20px 23px;
    border-bottom: 1px solid #eee;

    .powerbank-income_item {
      position: relative;

      &:nth-child(odd) {
        &::after {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          bottom: 0;
          width: 1px;
          height: 20px;
          background-color: #eee;
        }
      }
    }
  }
}

.color-primary {
  color: #fd6e05;
}

.text-label {
  color: #666;
  font-size: 12px;
  opacity: 0.85;
}

.text-value {
  margin-top: 6px;
  color: #333;
  font-size: 16px;
}

.text-label-primary {
  color: #fff;
  font-size: 12px;
}

.text-value-primary {
  margin-top: 6px;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
}

.text-title-lg {
  margin-top: 6px;
  font-size: 25px;
  color: #fff;
}

.text-section-title {
  color: #333;
  font-size: 15px;
  font-weight: 600;
}

.text-more {
  color: #999;
  font-size: 12px;
}

.btn-more {
  display: flex;
  align-items: center;
  line-height: 16px;

  img {
    width: 16px;
    height: 16px;
  }
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.empty-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  color: #999;

  img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    object-fit: contain;
  }
}

.device-item_text {
  padding-left: 21px;
  margin-top: 10px;
  font-size: 11px;
  color: #333;

  .label {
    color: #999;
  }
}
</style>
