import { get, post } from './http'

// 设备 - 列表
export const getDeviceList = () => post('/api/merchant/device/list', { page: 1, pageSize: 99999 })

// 设备 - 今日交易金额
export const getDeviceTransactionAmount = () => get('/api/merchant/transactionRecord/amount/date')

// 设备 - 今日交易笔数
export const getDeviceTransactionCount = () => get('/api/merchant/transactionRecord/count/date')

// 设备 - 主页统计
export const getDeviceChartData = (data) => post('/api/merchant/transactionRecord/dateStatistics', data)

// 充电宝 - 主页统计
export const getPowerBankStatistics = () => get('/api/merchant/power/bank/order/statistics')

// 充电宝 - 主页统计
export const getPowerBankChartData = (data) => post('/api/merchant/power/bank/order/dateStatistics', data)
